import {
  GET_RECEIVED_DOCUMENTS_LIST,
  GET_RECEIVED_DOCUMENTS_TRIP_LIST,
  GET_RECEIVED_DOCUMENTS_DETAIL,
  UPDATE_RECEIVED_DOCUMENTS_DETAIL,
  UPDATE_RECEIVED_DOCUMENTS_LIST,
  UPDATE_RECEIVED_DOCUMENTS_TRIP_DETAIL,
  GET_BANK_ITEMS,
} from 'shared/constants/ActionTypes';

const initialState = {
  receivedDocumentsList: [],
  receivedDocumentsTripList: [],
  receivedDocumentsBankItems: [],
  foundCount: 0,
  foundCountTrip: 0,
  noRecordsFound: false,
};

const receivedDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIVED_DOCUMENTS_LIST:
      return {
        ...state,
        receivedDocumentsList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };
    case GET_RECEIVED_DOCUMENTS_DETAIL:
      return {
        ...state,
        receivedDocumentsDetail: JSON.parse(action.payload),
      };

    case UPDATE_RECEIVED_DOCUMENTS_DETAIL: {
      const updatedDetail = {
        ...state.receivedDocumentsDetail,
        downloaded: true,
        downloadedName: action.payload.user,
        downloadedDate: action.payload.date,
        downloadedTime: action.payload.time,
      };

      const id = action.payload.id;
      const updatedlist = state.receivedDocumentsList.map((e) =>
        id === e.fieldData.Record_ID
          ? {...e, fieldData: {...e.fieldData, downloaded: 1}}
          : e,
      );

      state.receivedDocumentsList = updatedlist;

      return {
        ...state,
        receivedDocumentsDetail: updatedDetail,
      };
    }

    case UPDATE_RECEIVED_DOCUMENTS_LIST: {
      const idList = action.payload;
      const updatedlist = state.receivedDocumentsList.map((e) =>
        idList.includes(e.fieldData.Record_ID)
          ? {...e, fieldData: {...e.fieldData, downloaded: 1}}
          : e,
      );

      state.receivedDocumentsList = updatedlist;

      return {
        ...state,
        receivedDocumentsList: updatedlist,
      };
    }

    case GET_RECEIVED_DOCUMENTS_TRIP_LIST:
      return {
        ...state,
        receivedDocumentsTripList: action.payload.data,
        foundCountTrip: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };

    case UPDATE_RECEIVED_DOCUMENTS_TRIP_DETAIL: {
      const updatedDetail = {
        ...state.receivedDocumentsDetail,
        downloaded: true,
        downloadedName: action.payload.user,
        downloadedDate: action.payload.date,
        downloadedTime: action.payload.time,
      };

      const id = action.payload.id;
      const updatedlist = state.receivedDocumentsTripList.map((e) =>
        id === e.fieldData.Record_ID
          ? {...e, fieldData: {...e.fieldData, downloaded: 1}}
          : e,
      );

      state.receivedDocumentsTripList = updatedlist;

      return {
        ...state,
        receivedDocumentsDetail: updatedDetail,
      };
    }

    case GET_BANK_ITEMS:
      return {
        ...state,
        receivedDocumentsBankItems: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default receivedDocumentsReducer;
