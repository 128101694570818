import React from 'react';
//import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Notifications = React.lazy(() => import('./Notifications'));
const MemberPayments = React.lazy(() => import('./MemberPayments'));
const Budgets = React.lazy(() => import('./Budgets'));
const Infopoint = React.lazy(() => import('./Infopoint'));
const Statistic = React.lazy(() => import('./Statistic'));

export const dashBoardConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
      RoutePermittedRole.Accountant,
    ],
    path: '/dashboards/notifications',
    element: <Notifications />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Accountant,
    ],
    path: '/dashboards/memberpayments',
    element: <MemberPayments />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Accountant,
    ],
    path: ['/dashboards/budgets/:budget', '/dashboards/budgets'],
    element: <Budgets />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
    ],
    path: '/dashboards/infopoint',
    element: <Infopoint />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
    ],
    path: '/dashboards/statistic',
    element: <Statistic />,
  },
];
