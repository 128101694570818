import {GET_NOTIFICATIONS_LIST} from 'shared/constants/ActionTypes';

const initialState = {
  notificationsList: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationsList: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default notificationsReducer;
