import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchIconBox,
  SearchIconWrapper,
  SearchInputBase,
  SearchWrapper,
} from './index.style';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {Zoom} from '@mui/material';

const AppSearch = ({
  placeholder,
  iconPosition,
  align,
  overlap,
  onlyIcon,
  disableFocus,
  iconStyle,
  searchCloseBtn,
  searchValue,
  onSearchCloseBtn,
  ...rest
}) => {
  return (
    <>
      <SearchWrapper sx={rest.sx} iconPosition={iconPosition}>
        <SearchIconBox
          align={align}
          className={clsx(
            'searchRoot',
            {'hs-search': overlap},
            {'hs-disableFocus': disableFocus},
            {searchIconBox: onlyIcon},
          )}
        >
          <SearchIconWrapper
            className={clsx({
              right: true,
            })}
            style={iconStyle}
          >
            <SearchIcon
              sx={{
                color: searchValue ? (theme) => theme.palette.primary.main : '',
              }}
            />
          </SearchIconWrapper>

          <SearchInputBase
            {...rest}
            placeholder={placeholder || 'Search…'}
            inputProps={{
              'aria-label': 'search',
              autoComplete: 'none',
              spellCheck: false,
            }}
          />
        </SearchIconBox>
      </SearchWrapper>
      {searchCloseBtn && searchValue ? (
        <Zoom in style={{transitionDelay: '500ms'}}>
          <IconButton
            onClick={onSearchCloseBtn}
            sx={{
              p: 2,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Zoom>
      ) : null}
    </>
  );
};

export default AppSearch;

AppSearch.propTypes = {
  iconPosition: PropTypes.string,
  align: PropTypes.string,
  placeholder: PropTypes.string,
  overlap: PropTypes.bool,
  borderLight: PropTypes.bool,
  className: PropTypes.string,
  onlyIcon: PropTypes.bool,
  disableFocus: PropTypes.bool,
  iconStyle: PropTypes.object,
  searchCloseBtn: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearchCloseBtn: PropTypes.func,
};

AppSearch.defaultProps = {
  onlyIcon: false,
  overlap: true,
  iconPosition: 'left',
  align: 'left',
  iconStyle: {
    color: 'grey',
  },
};
