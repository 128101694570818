import React, {useState, useEffect} from 'react';
import AppGridContainer from '@main/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import IntlMessages from '@main/utility/IntlMessages';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import {Form} from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {ThemeMode} from 'shared/constants/AppEnums';
import {useSidebarActionsContext} from '@main/utility/AppContextProvider/SidebarContextProvider';
import {
  useThemeActionsContext,
  useThemeContext,
} from '@main/utility/AppContextProvider/ThemeContextProvider';
import {
  DarkSidebar,
  LightSidebar,
} from '@main/utility/AppContextProvider/defaultConfig';
import themeColorSets from 'shared/constants/ColorSets';

const NotificationForm = ({values, setFieldValue, user}) => {
  const [loginEmail, setLoginEmail] = useState(values.loginEmail);
  const [darkMode, setDarkMode] = useState(values.darkMode);

  const {updateThemeMode} = useThemeActionsContext();
  const {updateSidebarColorSet} = useSidebarActionsContext();
  const {themeMode, theme} = useThemeContext(); //eslint-disable-line
  const {updateTheme} = useThemeActionsContext();

  const onModeChange = (themeMode) => {
    if (themeMode) {
      updateThemeMode(themeMode);
      if (themeMode === ThemeMode.LIGHT) {
        updateSidebarColorSet({
          sidebarBgColor: LightSidebar.sidebarBgColor,
          sidebarTextColor: LightSidebar.sidebarTextColor,
          sidebarMenuSelectedBgColor: LightSidebar.sidebarMenuSelectedBgColor,
          sidebarMenuSelectedTextColor:
            LightSidebar.sidebarMenuSelectedTextColor,
          sidebarHeaderColor: LightSidebar.sidebarHeaderColor,
        });
      } else {
        updateSidebarColorSet({
          sidebarBgColor: DarkSidebar.sidebarBgColor,
          sidebarTextColor: DarkSidebar.sidebarTextColor,
          sidebarMenuSelectedBgColor: DarkSidebar.sidebarMenuSelectedBgColor,
          sidebarMenuSelectedTextColor:
            DarkSidebar.sidebarMenuSelectedTextColor,
          sidebarHeaderColor: DarkSidebar.sidebarHeaderColor,
        });
      }
    }
  };

  const updateThemeColors = (colorSet) => {
    theme.palette.primary.main = colorSet.primary.main;
    theme.palette.secondary.main = colorSet.secondary.main;
    theme.palette.background = colorSet.background;
    theme.palette.mode = colorSet.mode;
    theme.palette.text = colorSet.text;
    updateTheme({...theme});
  };

  useEffect(() => {
    if (darkMode) {
      onModeChange('dark');
      updateThemeColors(themeColorSets[19]);
    } else {
      onModeChange('light');
      updateThemeColors(themeColorSets[7]);
    }
  }, [darkMode]);

  return (
    <Form autoComplete='off'>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={12}>
          <Box sx={{mb: 1.5}}>
            <FormControlLabel
              control={
                <Switch
                  name='loginEmail'
                  checked={loginEmail}
                  onChange={(event) => {
                    setLoginEmail(event.target.checked);
                    setFieldValue('loginEmail', event.target.checked);
                  }}
                  color='primary'
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label='Zasielať email po prihálesní'
            />
          </Box>
          <Box sx={{mb: 1.5}}>
            <FormControlLabel
              control={
                <Switch
                  name='darkMode'
                  checked={darkMode}
                  onChange={(event) => {
                    setDarkMode(event.target.checked);
                    setFieldValue('darkMode', event.target.checked);
                  }}
                  color='primary'
                  inputProps={{'aria-label': 'controlled'}}
                />
              }
              label='Tmavý režim'
            />
          </Box>
          <Box sx={{mb: 1.5, visibility: 'hidden'}}>
            <FormControlLabel control={<Switch />} label='test' />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 'auto',
              }}
              color='primary'
              variant='contained'
              disableRipple
              disabled={
                _.isEqual(
                  {
                    loginEmail: user.loginEmail === 1 ? true : false,
                    darkMode: user.darkMode === 1 ? true : false,
                  },
                  values,
                )
                  ? true
                  : false
              }
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default NotificationForm;
NotificationForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.object,
};
