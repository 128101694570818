import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_PARTERS_LIST,
  GET_PARTNER_FILTER,
  GET_PARTNER_VALUE_LIST,
  GET_PARTNER_DETAIL,
  UPDATE_PARTNER_DETAIL,
  UPDATE_ORDER_DETAIL,
  DATA_HUB_PARTNER,
  MEMBER_PAYMENTS,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetPartnersList = (offset, limit) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .get(`/layouts/partner_list/records?_offset=${offset}&_limit=${limit}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_PARTERS_LIST, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetPartnerFilter = (
  filterValue,
  filterText,
  offset,
  limit,
  companyId,
) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    var request = {
      query: [
        {
          textToFind: `*${filterText}*`,
          ParterTypeNameAlias: filterValue === 'all' ? '*' : `==${filterValue}`,
          Organizacia_ID: `==${companyId}`,
        },
      ],
      offset: offset,
      limit: limit,
    };

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/partner_list/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_PARTNER_FILTER, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PARTNER_FILTER, payload: emtyPayload});
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onGetPartnerTypeList = () => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(`/layouts/partner_list/script/partnerValueLists?`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_PARTNER_VALUE_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetSelectedPartner = (id) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .get(`/layouts/partner_detail/records/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_PARTNER_DETAIL, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdatePartnerDetail = (id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/partner_detail/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          fmAxios.get(`/layouts/partner_detail/records/${id}`).then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: UPDATE_PARTNER_DETAIL,
                payload: data.data['response'],
              });
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Údaje boli uložené',
              });
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: messages['message.somethingWentWrong'],
              });
            }
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateMemberFee = (partnerId, id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/member_fees/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteMemberFee = (partnerId, recordId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/member_fees/records/${recordId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Položka bola zmazaná',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddMemberFee = (partnerId, recordId, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/member_fees/records`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Položka bola pridaná',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddContactListItem = (
  partnerId,
  recordId,
  request,
  tableName,
) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;

  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/contacts/records`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }

                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Položka bola pridaná',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateContactListItem = (partnerId, id, request, tableName) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/contacts/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }

                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteContactListItem = (partnerId, recordId, tableName) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/contacts/records/${recordId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }

                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Položka bola zmazaná',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddContact = (recordId, request, tableName) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/contacts/records`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${recordId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }

                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Kontakt bol pridaný',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateContact = (recordId, request, tableName) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/contacts/script/onUpdateContact?&script.param=${JSON.stringify(
          request,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${recordId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteContact = (recordId, request, tableName) => {
  let endPoint =
    tableName == 'Partneri'
      ? 'partner_detail'
      : tableName == 'Objednavky'
      ? 'order_detail'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/contacts/script/onDeleteContact?&script.param=${JSON.stringify(
          request,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${recordId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                if (tableName == 'Partneri') {
                  dispatch({
                    type: UPDATE_PARTNER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }

                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Kontakt bol zmazaný',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDataHub = (data) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/partner_detail/script/dataHub?&script.param=${JSON.stringify(
          data,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: DATA_HUB_PARTNER,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateDocument = (partnerId, id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/documents/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteDocument = (partnerId, recordId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/documents/records/${recordId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Dokument bol zmazaný',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddDocument = (partnerId, recordId, request, file) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .post(`/layouts/documents/records`, request)
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          const formData = new FormData();
          formData.append('upload', file);

          fmAxios
            .post(
              `/layouts/documents/records/${data.data.response.recordId}/containers/Document_Container/1`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            )
            .then((data) => {
              if (data.status === 200) {
                fmAxios
                  .get(`/layouts/partner_detail/records/${partnerId}`)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      sessionStorage.setItem(
                        'auth-token-timestamp',
                        JSON.stringify(new Date()),
                      );
                      dispatch({
                        type: GET_PARTNER_DETAIL,
                        payload: data.data['response'],
                      });
                      dispatch({
                        type: SHOW_MESSAGE,
                        payload: 'Dokument bol pridaný',
                      });
                    } else {
                      dispatch({
                        type: FETCH_ERROR,
                        payload: messages['message.somethingWentWrong'],
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                  });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddNewAttachment = (partnerId, recordId, file) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    const formData = new FormData();
    formData.append('upload', file);

    fmAxios
      .post(
        `/layouts/documents/records/${recordId}/containers/Document_Container/1`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/partner_detail/records/${partnerId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_PARTNER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Súbor bol uložený',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onMemberPayments = (data) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/partner_detail/script/onMemberPayments?&script.param=${JSON.stringify(
          data,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          dispatch({
            type: MEMBER_PAYMENTS,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
