import React from 'react';
import {Box, Typography} from '@mui/material';
import {Fonts} from 'shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '@main/services/auth/fm-auth/index';
import {useDispatch} from 'react-redux';
import {useAuthMethod} from '@main/utility/AuthHooks';
import 'react-notifications-component/dist/theme.css';
import {Store} from 'react-notifications-component';

const validationSchema = yup.object({
  oldPassword: yup.string().required('Zadajte heslo.'),
  newPassword: yup
    .string()
    .required('Zadajte heslo.')
    .min(6, 'Heslo je príliš krátkke - musí obsahovať minimálne 6 znakov.')
    .matches(
      /^[A-Za-z0-9]+$/,
      'Heslo môže obsahovať len čísla a znaky bez diakritiky.',
    ),
  retypeNewPassword: yup
    .string()
    .required('Zadajte heslo.')
    .oneOf([yup.ref('newPassword'), null], 'Heslá sa musia zhodovať'),
});

const ChangePassword = ({user}) => {
  const {logout} = useAuthMethod();
  const dispatch = useDispatch();
  const {messages} = appIntl();

  const handleSubmit = (oldPassword, newPasswordword) => {
    var parameter = btoa(`${user.acName}:${oldPassword}`);
    const customConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + parameter,
      },
    };

    dispatch({type: FETCH_START});
    fmAxios
      .post('/sessions', {}, customConfig)
      .then((data) => {
        let token = data.data.response.token;

        fmAxios
          .get(
            `/layouts/common/script/passwordReset?&script.param=${newPasswordword}`,
          )
          .then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});

              if (data.data.response.scriptError == 0) {
                Store.addNotification({
                  title: 'Heslo bolo zmenené!',
                  message: 'Počkajte na odhlásenie....',
                  type: 'Success',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
                fmAxios.delete(`/sessions/${token}`);
                setTimeout(() => {
                  logout();
                }, 4000);
              }
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: messages['message.somethingWentWrong'],
              });
            }
          });
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR});

        Store.addNotification({
          title: 'Neplatné heslo!',
          message: error.response.data.messages[0].message,
          type: 'Danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        Zmena hesla
      </Typography>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          retypeNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);
          handleSubmit(data.oldPassword, data.newPassword);
          setSubmitting(false);
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
    </Box>
  );
};

export default ChangePassword;

ChangePassword.propTypes = {
  user: PropTypes.object,
};
