import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import * as yup from 'yup';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import InfoForm from './InfoForm';
import {Formik} from 'formik';
import dayjs from 'dayjs';
import {onUpdateUser} from 'redux/actions';
import {useDispatch} from 'react-redux';
import {convertDateFM_API} from '@main/utility/Utils';

const validationSchema = yup.object({
  birthDate: yup.date().typeError('Nesprávny formát dátumu').nullable(),
});
const Information = ({user}) => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        Ostatné údaje
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          birthDate: user.birthDate ? dayjs(user.birthDate) : null,
          birthCountry: user.birthCountry
            ? JSON.parse(user.birthCountry)
            : null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);
          let request = {
            fieldData: {
              birthDate: data.birthDate
                ? convertDateFM_API(data.birthDate)
                : '',
              birthCountry: JSON.stringify(data.birthCountry),
            },
          };
          setSubmitting(false);
          dispatch(onUpdateUser(user.recordId, request));
        }}
      >
        {({values, setFieldValue}) => {
          return (
            <InfoForm
              values={values}
              user={user}
              setFieldValue={setFieldValue}
            />
          );
        }}
      </Formik>
    </Box>
  );
};

export default Information;

Information.propTypes = {
  user: PropTypes.object,
};
