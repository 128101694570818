import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SHOW_INFO,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  GET_EXPERIENCES_LIST,
  GET_LOCALITY_LIST,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  info: '',
  experiencesList: [],
  localityList: [],
  experiencesCount: 0,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', info: '', loading: true};
    }
    case UPDATING_CONTENT: {
      return {
        ...state,
        error: '',
        message: '',
        info: '',
        updatingContent: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        info: '',
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        info: '',
        message: action.payload,
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_INFO: {
      return {
        ...state,
        error: '',
        message: '',
        info: action.payload,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        info: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        info: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }
    case GET_EXPERIENCES_LIST: {
      return {
        ...state,
        experiencesList: JSON.parse(action.payload),
      };
    }
    case GET_LOCALITY_LIST: {
      return {
        ...state,
        localityList: JSON.parse(action.payload),
      };
    }
    default:
      return state;
  }
};
export default commonReducer;
