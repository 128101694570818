import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import Partners from './Partners';
import Orders from './Orders';
import Notifications from './Notifications';
import BudgetSpending from './BudgetSpending';
import Infopoint from './Infopoint';
import ReceivedDocuments from './ReceivedDocuments';
import Events from './Events';
import Papaya from './Papaya';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    dashboard: Dashboard,
    common: Common,
    partners: Partners,
    orders: Orders,
    notifications: Notifications,
    budgetSpending: BudgetSpending,
    infopoint: Infopoint,
    receivedDocuments: ReceivedDocuments,
    events: Events,
    papaya: Papaya,
  });
export default reducers;
