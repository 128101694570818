import React from 'react';
import {toggleNavCollapsed} from 'redux/actions';
import {useDispatch} from 'react-redux';
//import SearchBar from '../../../AppSearchBar';
import AppLogo from '../../components/AppLogo';
import Hidden from '@mui/material/Hidden';
//import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
//import BitBucketHeaderWrapper from './BitBucketHeaderWrapper';
import CustomHeaderWrapper from './CustomHeaderWrapper';

const AppMobileHeader = () => {
  const dispatch = useDispatch();

  return (
    <Hidden lgUp>
      <CustomHeaderWrapper className='bit-bucket-header'>
        <IconButton
          edge='start'
          className='menu-btn'
          color='inherit'
          aria-label='open drawer'
          onClick={() => {
            dispatch(toggleNavCollapsed());
          }}
        >
          <MenuIcon className='menu-icon' />
        </IconButton>
        <AppLogo />
      </CustomHeaderWrapper>
    </Hidden>
  );
};
export default AppMobileHeader;
