import {
  GET_STOCK_ITEMS_LIST,
  ADD_TO_STOCK_ITEMS_LIST,
  GET_STOCK_ITEM_DETAIL,
  GET_STOCK_ITEM_VALUE_LIST,
  UPDATE_STOCK_ITEM,
  GET_STOCK_ITEM_MOVEMENTS,
  UPDATE_STOCK_ITEM_MOVEMENTS,
  GET_INVOICED_ITEMS,
} from 'shared/constants/ActionTypes';
import dayjs from 'dayjs';
import {convertPapayaDate} from '@main/utility/Utils';

const initialState = {
  stockValueList: [],
  stockItemList: [],
  stockItemValueList: [],
  stockItemCount: 0,
  selectedStockItem: [],
  stockItemMovements: [],
  invoicedItems: [],
};

const papayaReducer = (state = initialState, action) => {
  const papayaItemConversion = (itemDetail, data) => {
    let obj = [];

    const typeLocalized = (value, type) => {
      switch (value) {
        case 1:
          return 'Spotreba';

        case 2:
          return 'Predaj';

        case 3:
          return 'Vrátenie spotreby';

        case 4:
          return 'Vrátenie predaja';

        case 5:
          return 'Inventúrny prebytok';

        case 6:
          return 'Inventúrne manko';

        case 7:
          return 'Odpis';

        default:
          return type === 'RECEIPT_CARD' ? 'Príjem na sklad' : 'Error';
      }
    };

    data.map((e) => {
      let additionalKeys = {
        accType: e.accType,
        accTypeLocalized: typeLocalized(e.accType, e.type),
        createTime: e.placedTime,
        inventoryId: e.inventoryId,
        type: e.type,
        documentType: e.documentType,
        date: dayjs(convertPapayaDate(e.placedTime)),
        note: e.note,
        supplier: e.supplier,
      };

      e.items.map((item) => {
        let total = item.priceNet * item.amount;
        let priceWithTax =
          item.priceNet * (1 + item.vatRate / 100) * item.amount;
        let invoicedId = state.invoicedItems.find((f) => item.id === f.id);

        let x = {
          totalWithoutTax: total,
          totalWithTax: priceWithTax,
          invoicedId: invoicedId ? invoicedId.invoicedId : 0,
          supplierInvoiceNumber: invoicedId
            ? invoicedId.supplierInvoiceNumber
            : 0,
        };

        let additionalData = itemDetail.find(
          (e) => item.stockItemId === e.itemId,
        );

        let contractPrice =
          additionalData != undefined ? additionalData.contractPrice : 0;
        let totalContractPrice = {
          totalContractPrice: contractPrice * item.amount,
        };

        obj.push({
          ...additionalKeys,
          ...additionalData,
          ...item,
          ...x,
          ...totalContractPrice,
        });
      });
    });

    return obj;
  };
  switch (action.type) {
    case GET_STOCK_ITEM_MOVEMENTS: {
      const data = action.payload.data;
      const itemDetailList = action.payload.itemsDetail;
      const updatedlist = papayaItemConversion(itemDetailList, data);
      state.stockItemMovements = updatedlist;

      return {
        ...state,
      };
    }

    case GET_STOCK_ITEM_VALUE_LIST:
      return {
        ...state,
        stockItemValueList: JSON.parse(action.payload),
      };

    case GET_STOCK_ITEMS_LIST:
      return {
        ...state,
        stockItemList: action.payload.data,
        stockItemCount: action.payload.dataInfo.foundCount,
      };

    case ADD_TO_STOCK_ITEMS_LIST: {
      let record = action.payload.data[0];
      state.stockItemCount = state.stockItemCount + 1;
      state.stockItemList.push(record);

      return {
        ...state,
      };
    }

    case GET_STOCK_ITEM_DETAIL:
      return {
        ...state,
        selectedStockItem: action.payload.data[0].fieldData,
      };

    case UPDATE_STOCK_ITEM: {
      let id = action.payload.data[0].recordId;

      const updatedlist = state.stockItemList.map((e) =>
        id === e.recordId ? action.payload.data[0] : e,
      );

      state.stockItemList = updatedlist;
      return {
        ...state,
      };
    }

    case GET_INVOICED_ITEMS:
      return {
        ...state,
        invoicedItems: action.payload,
      };

    case UPDATE_STOCK_ITEM_MOVEMENTS: {
      let id = action.payload.id;
      let account_Id = action.payload.account_Id;

      const updatedlist = state.stockItemMovements.map((e) =>
        id === e.id ? {...e, invoicedId: account_Id} : e,
      );

      state.stockItemMovements = updatedlist;
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default papayaReducer;
