import React from 'react';
import Box from '@mui/material/Box';
import NotificationForm from './NotificationForm';
import {Typography} from '@mui/material';
//import * as yup from 'yup';
import {Fonts} from 'shared/constants/AppEnums';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {onUpdateUser} from 'redux/actions';
import {useDispatch} from 'react-redux';

const Notification = ({user}) => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        Nastavenia
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          loginEmail: user.loginEmail === 1 ? true : false,
          darkMode: user.darkMode === 1 ? true : false,
        }}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);
          let request = {
            fieldData: {
              loginEmail: data.loginEmail ? 1 : 0,
              darkMode: data.darkMode ? 1 : 0,
            },
          };
          setSubmitting(false);
          dispatch(onUpdateUser(user.recordId, request));
        }}
      >
        {({values, setFieldValue}) => {
          return (
            <NotificationForm
              values={values}
              user={user}
              setFieldValue={setFieldValue}
            />
          );
        }}
      </Formik>
    </Box>
  );
};

export default Notification;

Notification.propTypes = {
  user: PropTypes.object,
};
