import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {useAuthUser} from '@main/utility/AuthHooks';
import {BiUser} from 'react-icons/bi';
import {AiOutlineLock} from 'react-icons/ai';
import {IoMdInformationCircleOutline} from 'react-icons/io';
import {CiSettings} from 'react-icons/ci';
import AccountTabsWrapper from './AccountTabsWrapper';
import PersonalInfo from './PersonalInfo';
import ChangePassword from './ChangePassword';
import Information from './Information';
import Notification from './Notification';
import AppAnimate from '@main/core/AppAnimate';
import {Fonts} from 'shared/constants/AppEnums';
import AppInfoView from '@main/core/AppInfoView';
import {ReactNotifications} from 'react-notifications-component';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = [
  {id: 1, icon: <BiUser />, name: 'Užívateľ'},
  {
    id: 2,
    icon: <IoMdInformationCircleOutline />,
    name: 'Ostatné údaje',
  },
  {
    id: 3,
    icon: <AiOutlineLock />,
    name: 'Zmena hesla',
  },
  {
    id: 4,
    icon: <CiSettings />,
    name: 'Nastavenia',
  },
];

const Account = () => {
  const [value, setValue] = React.useState(0);
  const {user} = useAuthUser();

  const onTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        component='h2'
        variant='h2'
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4,
          },
        }}
      >
        Môj účet
      </Box>
      <AppAnimate animation='transition.slideUpIn' delay={0}>
        <AccountTabsWrapper>
          <Tabs
            className='account-tabs'
            value={value}
            onChange={onTabsChange}
            aria-label='basic tabs example'
            orientation='vertical'
          >
            {tabs.map((tab, index) => (
              <Tab
                className='account-tab'
                label={tab.name}
                icon={tab.icon}
                key={index}
                sx={{my: 1}}
                disableRipple
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          <Box className='account-tabs-content'>
            {value === 0 && <PersonalInfo user={user} />}
            {value === 1 && <Information user={user} />}
            {value === 2 && <ChangePassword user={user} />}
            {value === 3 && <Notification user={user} />}
          </Box>
          <ReactNotifications />
        </AccountTabsWrapper>
      </AppAnimate>

      <AppInfoView />
    </>
  );
};

export default Account;
