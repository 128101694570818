import React from 'react';
import {Provider} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import AuthRoutes from '@main/utility/AuthRoutes';
import AppContextProvider from '@main/utility/AppContextProvider';
import AppThemeProvider from '@main/utility/AppThemeProvider';
import AppStyleProvider from '@main/utility/AppStyleProvider';
import AppLocaleProvider from '@main/utility/AppLocaleProvider';
import AppLayout from '@main/core/AppLayout';
import configureStore, {history} from 'redux/store';
import FmAuthProvider from '@main/services/auth/fm-auth/FmAuthProvider';
import {BrowserRouter} from 'react-router-dom';

const store = configureStore();

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <AppThemeProvider>
        <AppStyleProvider>
          <AppLocaleProvider>
            <BrowserRouter history={history}>
              <FmAuthProvider>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                </AuthRoutes>
              </FmAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppStyleProvider>
      </AppThemeProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
