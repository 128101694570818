// ForFM Auth

import {getUserFromFmAuth} from './helper/AuthHelper';
import {
  useFMAuth,
  useFMAuthActions,
} from '../services/auth/fm-auth/FmAuthProvider';

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading, password} = useFMAuth();

  let forcePasswordChange =
    password != undefined && password.includes('12345') ? true : false;

  return {
    isLoading,
    isAuthenticated,
    user: getUserFromFmAuth(user),
    forcePasswordChange: forcePasswordChange,
  };
};

export const useAuthMethod = () => {
  const {signInUser, signUpUser, logout} = useFMAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};

// ForJWT Auth
/*import { getUserFromJwtAuth } from "./helper/AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "../services/auth/jwt-auth/JWTAuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const { signInUser, signUpUser, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};*/
