import React from 'react';
import AppGridContainer from '@main/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IntlMessages from '@main/utility/IntlMessages';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import {Form} from 'formik';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import countries from '@main/services/db/account/countries';
import AppDateFiled from '@main/core/AppFormComponents/AppDateFiled';
import _ from 'lodash';
import dayjs from 'dayjs';

const InfoForm = ({values, setFieldValue, user}) => {
  return (
    <Form autoComplete='off'>
      <AppGridContainer spacing={4}>
        <Grid item xs={12} md={8}>
          <AppDateFiled
            autoOk
            fullWidth
            variant='outlined'
            inputVariant='outlined'
            label='Narodeniny'
            labelId='label-date-outlined-label'
            name='birthDate'
            value={values.birthDate}
            onChange={(value) => setFieldValue('birthDate', value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Autocomplete
            id='country-select'
            fullWidth
            value={values.birthCountry}
            options={countries}
            name='birthCountry'
            onChange={(_, newValue) => {
              setFieldValue('birthCountry', newValue);
            }}
            getOptionLabel={(option) => option.label}
            freeSolo
            renderOption={(props, option) => (
              <Box
                component='li'
                sx={{'& > img': {mr: 2, flexShrink: 0}}}
                {...props}
              >
                <img
                  loading='lazy'
                  width='20'
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=''
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Krajina narodenia'
                inputProps={{
                  ...params.inputProps,
                  // autoComplete: 'new-password', // disable autocomplete and autofill
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 'auto',
              }}
              color='primary'
              variant='contained'
              disableRipple
              disabled={
                _.isEqual(
                  {
                    birthDate: user.birthDate ? dayjs(user.birthDate) : null,
                    birthCountry: user.birthCountry
                      ? JSON.parse(user.birthCountry)
                      : null,
                  },
                  values,
                )
                  ? true
                  : false
              }
              type='submit'
            >
              <IntlMessages id='common.saveChanges' />
            </Button>
          </Box>
        </Grid>
      </AppGridContainer>
    </Form>
  );
};

export default InfoForm;
InfoForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.object,
};
