import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ATTENDANCE_LIST,
  GET_ATTENDANCE_VALUE_LIST,
  GET_INFOPOINT_DASHBORD,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetAttendenceValueList = (companyId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/common/script/attendanceValueList?&script.param=${companyId}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_ATTENDANCE_VALUE_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetAttendanceList = (request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/attandaceData/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_ATTENDANCE_LIST, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
            noRecordsFound: true,
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ATTENDANCE_LIST, payload: emtyPayload});
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onAddAttendance = (dataRequest, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/attandaceData/records`, dataRequest)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch(onGetAttendanceList(request));
          setTimeout(() => {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Záznam bol pridaný',
            });
          }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        var fmServerError = error.response.data.messages[0].message;

        dispatch({
          type: FETCH_ERROR,
          payload: fmServerError ? fmServerError : error.message,
        });
      });
  };
};

export const onAddAttendanceScript = (dataRequest, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/attandaceData/script/onAddAttendanceScript?&script.param=${JSON.stringify(
          dataRequest,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch(onGetAttendanceList(request));
          setTimeout(() => {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Záznam bol pridaný',
            });
          }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        var fmServerError = error.response.data.messages[0].message;
        dispatch({
          type: FETCH_ERROR,
          payload: fmServerError ? fmServerError : error.message,
        });
      });
  };
};

export const onUpdateAttendance = (dataRequest, id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/attandaceData/records/${id}`, dataRequest)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch(onGetAttendanceList(request));
          setTimeout(() => {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Údaje boli uložené',
            });
          }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        var fmServerError = error.response.data.messages[0].message;
        dispatch({
          type: FETCH_ERROR,
          payload: fmServerError ? fmServerError : error.message,
        });
      });
  };
};

export const onDeleteAttendance = (id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/attandaceData/records/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch(onGetAttendanceList(request));
          setTimeout(() => {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Záznam bol zmazaný',
            });
          }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onMultiDeleteScript = (dataRequest, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/attandaceData/script/onMultiDeleteScript?&script.param=${JSON.stringify(
          dataRequest,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch(onGetAttendanceList(request));
          setTimeout(() => {
            dispatch({
              type: SHOW_MESSAGE,
              payload: 'Záznamy boli zmazané',
            });
          }, 500);
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetInfopointDashboardData = (companyId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/attandaceData/script/onGetInfopointDashboardData?&script.param=${companyId}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_INFOPOINT_DASHBORD,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
