import {
  GET_ORDERS_LIST,
  GET_ORDER_FILTER,
  GET_ORDER_VALUE_LIST,
  GET_ORDER_DETAIL,
  UPDATE_ORDER_DETAIL,
} from 'shared/constants/ActionTypes';

const initialState = {
  ordersList: [],
  totalCount: 0,
  orderValueList: [],
  selectedOrder: [],
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_LIST:
      return {
        ...state,
        ordersList: action.payload.data,
        totalCount: action.payload.dataInfo.totalRecordCount,
      };

    case GET_ORDER_FILTER:
      return {
        ...state,
        ordersList: action.payload.data,
        totalCount: action.payload.dataInfo.foundCount,
      };

    case GET_ORDER_VALUE_LIST:
      return {
        ...state,
        orderValueList: JSON.parse(action.payload),
      };

    case GET_ORDER_DETAIL:
      return {
        ...state,
        orderData: action.payload.data[0],
      };

    case UPDATE_ORDER_DETAIL: {
      let id = action.payload.data[0].recordId;

      const updatedlist = state.ordersList.map((e) =>
        id === e.recordId ? action.payload.data[0] : e,
      );

      state.ordersList = updatedlist;
      return {
        ...state,
        orderData: action.payload.data[0],
      };
    }

    default:
      return state;
  }
};
export default ordersReducer;
