import saMessages from '../locales/sk_SK.json';
import {skSK} from '@mui/material/locale';

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: skSK,
  locale: 'sk-SK',
};
export default saLang;
