import {GET_STATISTIC_DASHBOARD} from 'shared/constants/ActionTypes';

const initialState = {
  statisticData: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTIC_DASHBOARD:
      return {
        ...state,
        statisticData: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default dashboardReducer;
