import React, {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
//import AppLngSwitcher from '@main/core/AppLngSwitcher';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import {toggleNavCollapsed} from 'redux/actions';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch} from 'react-redux';
import UserInfo from '../../../components/UserInfo';
import SessionTimer from '@main/services/auth/fm-auth/SessionTimer';
//import AppThemeSetting from '@main/core/AppThemeSetting';
import {useAuthUser} from '@main/utility/AuthHooks';

import {ThemeMode} from 'shared/constants/AppEnums';
import {useSidebarActionsContext} from '@main/utility/AppContextProvider/SidebarContextProvider';
import {
  useThemeActionsContext,
  useThemeContext,
} from '@main/utility/AppContextProvider/ThemeContextProvider';
import {
  DarkSidebar,
  LightSidebar,
} from '@main/utility/AppContextProvider/defaultConfig';
import themeColorSets from 'shared/constants/ColorSets';

const AppDesktopHeader = () => {
  //const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const notificationsList = useSelector(
  //   ({notifications}) => notifications.notificationsList,
  // );

  const dispatch = useDispatch();
  const {user} = useAuthUser();

  const {updateThemeMode} = useThemeActionsContext();
  const {updateSidebarColorSet} = useSidebarActionsContext();
  const {theme} = useThemeContext();
  const {updateTheme} = useThemeActionsContext();

  const onModeChange = (themeMode) => {
    if (themeMode) {
      updateThemeMode(themeMode);
      if (themeMode === ThemeMode.LIGHT) {
        updateSidebarColorSet({
          sidebarBgColor: LightSidebar.sidebarBgColor,
          sidebarTextColor: LightSidebar.sidebarTextColor,
          sidebarMenuSelectedBgColor: LightSidebar.sidebarMenuSelectedBgColor,
          sidebarMenuSelectedTextColor:
            LightSidebar.sidebarMenuSelectedTextColor,
          sidebarHeaderColor: LightSidebar.sidebarHeaderColor,
        });
      } else {
        updateSidebarColorSet({
          sidebarBgColor: DarkSidebar.sidebarBgColor,
          sidebarTextColor: DarkSidebar.sidebarTextColor,
          sidebarMenuSelectedBgColor: DarkSidebar.sidebarMenuSelectedBgColor,
          sidebarMenuSelectedTextColor:
            DarkSidebar.sidebarMenuSelectedTextColor,
          sidebarHeaderColor: DarkSidebar.sidebarHeaderColor,
        });
      }
    }
  };

  const updateThemeColors = (colorSet) => {
    theme.palette.primary.main = colorSet.primary.main;
    theme.palette.secondary.main = colorSet.secondary.main;
    theme.palette.background = colorSet.background;
    theme.palette.mode = colorSet.mode;
    theme.palette.text = colorSet.text;
    updateTheme({...theme});
  };

  useEffect(() => {
    if (user.darkMode) {
      onModeChange('dark');
      updateThemeColors(themeColorSets[19]);
    } else {
      updateThemeColors(themeColorSets[7]);
    }
  }, []);

  return (
    <AppBar
      color='inherit'
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: 'background.paper',
        transition: 'width 0.5s ease',
        width: '100%',
      }}
      className='app-bar'
    >
      <Toolbar
        sx={{
          boxSizing: 'border-box',
          minHeight: {xs: 56, sm: 70},
          paddingLeft: {xs: 2.5, md: 5},
          paddingRight: {xs: 2.5, md: 5},
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{color: 'text.secondary'}}
            edge='start'
            className='menu-btn'
            color='inherit'
            aria-label='open drawer'
            onClick={() => {
              dispatch(toggleNavCollapsed());
            }}
            size='large'
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            '& .logo-text': {
              display: {xs: 'none', sm: 'block'},
            },
          }}
        >
          {/* <AppLogo /> */}
          {/* <AuthImgPanel /> */}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />

        <Box
          sx={{
            ml: 4,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* <Hidden smDown> */}
          <Box
            sx={{
              px: 1.85,
            }}
          >
            <SessionTimer />
          </Box>

          {/* <AppThemeSetting /> */}
          {/* <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                marginLeft: -2,
                marginRight: -2,
              }}
            >
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppNotifications />
              </Box>
              <Box
                sx={{
                  px: 1.85,
                }}
              >
                <AppMessages />
              </Box>
              <Box sx={{ml: 4}}>
                <AppLngSwitcher iconOnly={true} tooltipPosition='bottom' />
              </Box>
            </Box> */}
          {/* </Hidden> */}

          <Box
            sx={{
              ml: {sm: 4},
              mr: {xs: 4, sm: 0},
              minWidth: {md: 220},
              '& .user-info-view': {
                p: 0,
              },
              '& .user-info': {
                display: {xs: 'none', md: 'block'},
              },
            }}
          >
            <UserInfo />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppDesktopHeader;
