import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {onUpdateUser} from 'redux/actions';
import {useDispatch} from 'react-redux';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Povinný údaj'),
  fullName: yup.string().required('Povinný údaj'),
  staffPosition: yup.string().required('Povinný údaj'),
  phone: yup.string().required('Povinný údaj'),
});
const PersonalInfo = ({user}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: 550,
        }}
      >
        <Formik
          validateOnBlur={true}
          initialValues={{
            ...user,
            photoURL: user.photoURL
              ? user.photoURL
              : '/assets/images/placeholder.jpg',
          }}
          validationSchema={validationSchema}
          onSubmit={(data, {setSubmitting}) => {
            setSubmitting(true);
            let request = {
              fieldData: {
                email: data.email,
                Funkcia: data.staffPosition,
                fullName: data.fullName,
                Telefon: data.phone,
              },
            };
            setSubmitting(false);
            dispatch(onUpdateUser(data.recordId, request, file));
          }}
        >
          {({errors, touched, values, setFieldValue}) => {
            return (
              <PersonalInfoForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFile={setFile}
                user={user}
              />
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
  user: PropTypes.object,
};
