import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ORDERS_LIST,
  GET_ORDER_FILTER,
  GET_ORDER_VALUE_LIST,
  GET_ORDER_DETAIL,
  UPDATE_ORDER_DETAIL,
  GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
  UPDATE_BUDGET_SPENDING_LIST,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';
import {onGetNotificationsList} from 'redux/actions';

export const onGetOrdersList = (offset, limit) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .get(`/layouts/orders_list/records?_offset=${offset}&_limit=${limit}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_ORDERS_LIST, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetOrderFilter = (
  date,
  circle,
  status,
  budget,
  filterText,
  offset,
  limit,
  companyId,
) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    var request = {
      query: [
        {
          textToFind: `*${filterText}*`,
          OrderStatusAlias: status === 'all' ? '*' : `==${status}`,
          CreatedBy: circle === 'all' ? '*' : `==${circle}`,
          BudgetAlias: budget === 'all' ? '*' : `==${budget}`,
          Datum_vytvorenia: date === 'all' ? '*' : `==${date}`,
          Organizacia_ID: `==${companyId}`,
        },
      ],
      sort: [
        {
          fieldName: 'Datum_vytvorenia',
          sortOrder: 'descend',
        },
      ],
      offset: offset,
      limit: limit,
    };

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/orders_list/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_ORDER_FILTER, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ORDER_FILTER, payload: emtyPayload});
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onGetOrderValueList = (companyId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/orders_list/script/ordersValueLists?&script.param=${companyId}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_ORDER_VALUE_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetSelectedOrder = (id) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .get(`/layouts/order_detail/records/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({type: GET_ORDER_DETAIL, payload: data.data['response']});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateOrderDetail = (id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/order_detail/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          fmAxios.get(`/layouts/order_detail/records/${id}`).then((data) => {
            if (data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: UPDATE_ORDER_DETAIL,
                payload: data.data['response'],
              });
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Údaje boli uložené',
              });
            } else {
              dispatch({
                type: FETCH_ERROR,
                payload: messages['message.somethingWentWrong'],
              });
            }
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onOrderAction = (orderId, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/order_detail/script/onOrderAction?&script.param=${JSON.stringify(
          request,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          let scriptError = data.data.response.scriptResult;
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/order_detail/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_ORDER_DETAIL,
                  payload: data.data['response'],
                });
                if (scriptError == 0) {
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: 'Požiadavka bola spracovaná',
                  });
                  setTimeout(() => {
                    dispatch(onGetNotificationsList(request)); ///// try
                  }, 5000);
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: messages['message.somethingWentWrong'],
                  });
                }
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdatePriceOffer = (orderId, id, request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/priceOffers/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/order_detail/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_ORDER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddPriceOffer = (orderId, recordId, request, file) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .post(`/layouts/priceOffers/records`, request)
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          if (file) {
            const formData = new FormData();
            formData.append('upload', file);

            fmAxios
              .post(
                `/layouts/priceOffers/records/${data.data.response.recordId}/containers/Container_data/1`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )
              .then((data) => {
                if (data.status === 200) {
                  fmAxios
                    .get(`/layouts/order_detail/records/${orderId}`)
                    .then((data) => {
                      if (data.status === 200) {
                        dispatch({type: FETCH_SUCCESS});
                        sessionStorage.setItem(
                          'auth-token-timestamp',
                          JSON.stringify(new Date()),
                        );
                        dispatch({
                          type: UPDATE_ORDER_DETAIL,
                          payload: data.data['response'],
                        });
                        dispatch({
                          type: SHOW_MESSAGE,
                          payload: 'Cenová ponuka bola pridaná',
                        });
                      } else {
                        dispatch({
                          type: FETCH_ERROR,
                          payload: messages['message.somethingWentWrong'],
                        });
                      }
                    })
                    .catch((error) => {
                      dispatch({type: FETCH_ERROR, payload: error.message});
                    });
                }
              });
          } else {
            fmAxios
              .get(`/layouts/order_detail/records/${orderId}`)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  sessionStorage.setItem(
                    'auth-token-timestamp',
                    JSON.stringify(new Date()),
                  );
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: 'Cenová ponuka bola pridaná',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: messages['message.somethingWentWrong'],
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
              });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddPriceOfferAttachment = (orderId, recordId, file) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    const formData = new FormData();
    formData.append('upload', file);

    fmAxios
      .post(
        `/layouts/priceOffers/records/${recordId}/containers/Container_data/1`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/order_detail/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_ORDER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Súbor bol uložený',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeletePriceOffer = (orderId, recordId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/priceOffers/records/${recordId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/order_detail/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: UPDATE_ORDER_DETAIL,
                  payload: data.data['response'],
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Cenová ponuka bola zmazaná',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddFullfillment = (
  orderId,
  recordId,
  request,
  file,
  tableName,
) => {
  let endPoint =
    tableName == 'Objednavky'
      ? 'order_detail'
      : tableName == 'Doklady'
      ? 'purchaseDocuments'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .post(`/layouts/fulfillments/records`, request)
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          const formData = new FormData();
          formData.append('upload', file);

          fmAxios
            .post(
              `/layouts/fulfillments/records/${data.data.response.recordId}/containers/Container_data/1`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            )
            .then((data) => {
              if (data.status === 200) {
                fmAxios
                  .get(`/layouts/${endPoint}/records/${orderId}`)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      sessionStorage.setItem(
                        'auth-token-timestamp',
                        JSON.stringify(new Date()),
                      );
                      if (tableName == 'Objednavky') {
                        dispatch({
                          type: UPDATE_ORDER_DETAIL,
                          payload: data.data['response'],
                        });
                      }
                      if (tableName == 'Doklady') {
                        dispatch({
                          type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
                          payload: data.data['response'],
                        });
                        dispatch({
                          type: UPDATE_BUDGET_SPENDING_LIST,
                          payload: data.data['response'],
                        });
                      }
                      dispatch({
                        type: SHOW_MESSAGE,
                        payload: 'Plnenie bolo pridané',
                      });
                    } else {
                      dispatch({
                        type: FETCH_ERROR,
                        payload: messages['message.somethingWentWrong'],
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                  });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddFullfillmentScript = (orderId, request, tableName) => {
  let endPoint =
    tableName == 'Objednavky'
      ? 'order_detail'
      : tableName == 'Doklady'
      ? 'purchaseDocuments'
      : null;

  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/fulfillments/script/onAddFullfillment?&script.param=${JSON.stringify(
          request,
        )}`,
      )
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Doklady') {
                  dispatch({
                    type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
                    payload: data.data['response'],
                  });
                  dispatch({
                    type: UPDATE_BUDGET_SPENDING_LIST,
                    payload: data.data['response'],
                  });
                }
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Plnenie bolo pridané',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteFulfillment = (orderId, recordId, tableName, script) => {
  let endPoint =
    tableName == 'Objednavky'
      ? 'order_detail'
      : tableName == 'Doklady'
      ? 'purchaseDocuments'
      : null;

  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .delete(`/layouts/fulfillments/records/${recordId}${script}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Doklady') {
                  dispatch({
                    type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
                    payload: data.data['response'],
                  });
                  dispatch({
                    type: UPDATE_BUDGET_SPENDING_LIST,
                    payload: data.data['response'],
                  });
                }
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Plnenie bolo zmazané',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddFulfillmentAttachment = (
  orderId,
  recordId,
  file,
  tableName,
) => {
  let endPoint =
    tableName == 'Objednavky'
      ? 'order_detail'
      : tableName == 'Doklady'
      ? 'purchaseDocuments'
      : null;

  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    const formData = new FormData();
    formData.append('upload', file);

    fmAxios
      .post(
        `/layouts/fulfillments/records/${recordId}/containers/Container_data/1`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((data) => {
        if (data.status === 200) {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Doklady') {
                  dispatch({
                    type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
                    payload: data.data['response'],
                  });
                }
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Súbor bol uložený',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateFulfillment = (orderId, id, request, tableName) => {
  let endPoint =
    tableName == 'Objednavky'
      ? 'order_detail'
      : tableName == 'Doklady'
      ? 'purchaseDocuments'
      : null;
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .patch(`/layouts/fulfillments/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(`/layouts/${endPoint}/records/${orderId}`)
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                if (tableName == 'Objednavky') {
                  dispatch({
                    type: UPDATE_ORDER_DETAIL,
                    payload: data.data['response'],
                  });
                }
                if (tableName == 'Doklady') {
                  dispatch({
                    type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
                    payload: data.data['response'],
                  });
                  dispatch({
                    type: UPDATE_BUDGET_SPENDING_LIST,
                    payload: data.data['response'],
                  });
                }
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
