import {
  GET_ATTENDANCE_LIST,
  GET_ATTENDANCE_VALUE_LIST,
  GET_INFOPOINT_DASHBORD,
} from 'shared/constants/ActionTypes';

const initialState = {
  attendanceList: [],
  attendanceValueList: [],
  totalCount: 0,
  noRecordsFound: false,
  infopointDashboardData: null,
};

const infopointReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_LIST:
      return {
        ...state,
        attendanceList: action.payload.data,
        totalCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };

    case GET_ATTENDANCE_VALUE_LIST:
      return {
        ...state,
        attendanceValueList: JSON.parse(action.payload),
      };

    case GET_INFOPOINT_DASHBORD:
      return {
        ...state,
        infopointDashboardData: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default infopointReducer;
