import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from 'shared/constants/ActionTypes';
import fmAxios, {setAuthToken} from './index';

const FMAuthContext = createContext();
const FMAuthActionsContext = createContext();

export const useFMAuth = () => useContext(FMAuthContext);

export const useFMAuthActions = () => useContext(FMAuthActionsContext);

const FmAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = sessionStorage.getItem('auth-token');
      const loginName = sessionStorage.getItem('auth-name');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      setAuthToken(token);

      const isEmail = loginName.includes('@');

      var request = {
        query: [
          {
            Prihlasovacie_meno: isEmail
              ? loginName.toLowerCase()
              : loginName.charAt(0).toUpperCase() +
                loginName.slice(1).toLowerCase(),
          },
        ],
      };

      fmAxios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      fmAxios
        .post(`/layouts/user/_find`, request)
        .then(({data}) => {
          setJWTAuthData({
            user: data.response.data[0].fieldData,
            isLoading: false,
            isAuthenticated: true,
          }),
            sessionStorage.setItem('auth-name', loginName);
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({loginName, password}) => {
    dispatch({type: FETCH_START});

    const isEmail = loginName.includes('@');

    loginName = isEmail
      ? loginName.toLowerCase()
      : loginName.charAt(0).toUpperCase() + loginName.slice(1).toLowerCase();

    try {
      var parameter = btoa(`${loginName}:${password}`);
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + parameter,
        },
      };
      const {data} = await fmAxios.post('/sessions', {}, customConfig);
      setAuthToken(data['response'].token, loginName);

      var request = {
        query: [
          {
            Prihlasovacie_meno: loginName,
          },
        ],
      };

      fmAxios.defaults.headers.common = {
        Authorization: `Bearer ${data['response'].token}`,
      };

      fmAxios
        .post(`/layouts/user/_find`, request)
        .then(({data}) => {
          setJWTAuthData({
            user: data.response.data[0].fieldData,
            password: password,
            isLoading: false,
            isAuthenticated: true,
          });
          sessionStorage.setItem(
            'auth-fn',
            data.response.data[0].fieldData.signature,
          );
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );

      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({
        type: FETCH_ERROR,
        payload: error?.response?.data?.error || 'Nepodarilo sa prihlásiť',
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <FMAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <FMAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
        }}
      >
        {children}
      </FMAuthActionsContext.Provider>
    </FMAuthContext.Provider>
  );
};
export default FmAuthProvider;

FmAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
