import {
  GET_PARTERS_LIST,
  GET_PARTNER_DETAIL,
  GET_PARTNER_FILTER,
  GET_PARTNER_VALUE_LIST,
  UPDATE_PARTNER_DETAIL,
  DATA_HUB_PARTNER,
  MEMBER_PAYMENTS,
} from 'shared/constants/ActionTypes';

const initialState = {
  partnersList: [],
  totalCount: 0,
  partnerValueList: [],
  partnerDataHub: {},
  memberPayments: [],
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTERS_LIST:
      return {
        ...state,
        partnersList: action.payload.data,
        totalCount: action.payload.dataInfo.totalRecordCount,
      };

    case GET_PARTNER_FILTER:
      return {
        ...state,
        partnersList: action.payload.data,
        totalCount: action.payload.dataInfo.foundCount,
      };

    case GET_PARTNER_VALUE_LIST:
      return {
        ...state,
        partnerValueList: JSON.parse(action.payload),
      };

    case DATA_HUB_PARTNER:
      return {
        ...state,
        partnerDataHub: JSON.parse(action.payload),
      };

    case GET_PARTNER_DETAIL:
      return {
        ...state,
        partnerData: action.payload.data[0],
      };

    case MEMBER_PAYMENTS:
      return {
        ...state,
        memberPayments: JSON.parse(action.payload),
      };

    case UPDATE_PARTNER_DETAIL: {
      let id = action.payload.data[0].recordId;

      const updatedlist = state.partnersList.map((e) =>
        id === e.recordId ? action.payload.data[0] : e,
      );

      state.partnersList = updatedlist;
      return {
        ...state,
        partnerData: action.payload.data[0],
      };
    }

    default:
      return state;
  }
};
export default partnersReducer;
