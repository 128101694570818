import axios from 'axios';
import {host, database} from '../../../../shared/constants/AppConst';
import {osName, browserName, browserVersion} from 'react-device-detect';

const fmAxios = axios.create({
  baseURL: `https://${host}/fmi/data/vLatest/databases/${database}`,
  headers: {
    'Content-Type': 'application/json',
  },
});
fmAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token, loginName) => {
  if (token) {
    sessionStorage.setItem('auth-token', token);
    sessionStorage.setItem('auth-name', loginName);
    sessionStorage.setItem('auth-token-timestamp', JSON.stringify(new Date()));

    if (loginName) {
      fmAxios.defaults.headers.common = {
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      };
      fmAxios.get(
        `/layouts/common/script/loginMail?&script.param=${`${loginName}|${osName} ${browserName} ${browserVersion}`}`,
      );
    }
  } else {
    fmAxios.delete(`/sessions/${sessionStorage.getItem('auth-token', token)}`);
    sessionStorage.removeItem('auth-token');
    sessionStorage.removeItem('auth-name');
    sessionStorage.removeItem('auth-fn');
    sessionStorage.removeItem('auth-token-timestamp');
  }
};

export default fmAxios;
