import {useState, useEffect, useRef} from 'react';
import {useAuthMethod} from '../../../utility/AuthHooks';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {Box} from '@mui/material';
import moment from 'moment';
import AppTooltip from '@main/core/AppTooltip';
import {isMobile} from 'react-device-detect';

const SessionTimer = () => {
  const {logout} = useAuthMethod();
  const tokenTime = new Date(
    JSON.parse(sessionStorage.getItem('auth-token-timestamp')),
  );
  const tokenLifeTime = 870000;
  const currentTime = Date.now();

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  const convertMsToTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds,
    )}`;
  };

  const tokenRemainingTime = convertMsToTime(
    tokenLifeTime - (currentTime - tokenTime),
  );

  const Ref = useRef(null);

  const [timer, setTimer] = useState(tokenRemainingTime);
  const [key, setKey] = useState(0);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let {total, hours, minutes, seconds} = getTimeRemaining(e);
    if (total > 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      );
    } else {
      logout();
      window.location.reload(true);
    }
  };

  const clearTimer = (e) => {
    setTimer(tokenRemainingTime);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(
      deadline.getSeconds() +
        (tokenLifeTime - (currentTime - tokenTime)) / 1000,
    );
    return deadline;
  };

  // Refresh Circle Timer

  let savedTokenTime = moment(tokenTime).add(1, 'seconds').format('HH:mm:ss');
  let timeNow = moment(currentTime).format('HH:mm:ss');

  const circleStyle = {
    fontWeight: '400',
    fontSize: '0.72rem',
    cursor: 'default',
  };

  const renderTime = ({remainingTime}) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    const result =
      (minutes > 9 ? minutes : `0${minutes}`) +
      ':' +
      (seconds > 9 ? seconds : `0${seconds}`);

    return (
      <div className='timer'>
        <div className='value' style={circleStyle}>
          {result}
        </div>
      </div>
    );
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    if (timeNow === savedTokenTime) {
      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
      }, 1);
    }
  });

  return (
    <AppTooltip title={`Odhlásenie za ${timer}`}>
      <Box sx={{userSelect: 'none', scale: isMobile ? '0.8' : '1'}}>
        <CountdownCircleTimer
          key={key}
          isPlaying
          size={50}
          strokeWidth={tokenRemainingTime < '00:05:00' ? 5 : 3}
          isGrowing={true}
          isSmoothColorTransition={false}
          rotation='clockwise'
          duration={870}
          colors={['#5CF758', '#FA7A04', '#FC0F08', '#FC0F08']}
          colorsTime={[870, 300, 180, 0]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </Box>
    </AppTooltip>
  );
};

export default SessionTimer;
