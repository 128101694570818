const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  },
  {
    languageId: 'slovak',
    locale: 'sk',
    name: 'Slovak',
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: '中国人',
  // },
  // {
  //   languageId: 'spanish',
  //   locale: 'es',
  //   name: 'Español',
  // },
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'français',
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italiano',
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'عربي',
  // },
];
export default languageData;
