import {authRole} from 'shared/constants/AppConst';

export const getUserFromAuth0 = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.User,
    };
  return user;
};

export const getUserFromFirebase = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : 'Unknown User',
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
      role: authRole.User,
    };
  return user;
};
export const getUserFromAWS = (user) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : 'Unknown User',
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.User,
    };
  return user;
};

export const getUserFromJwtAuth = (user) => {
  try {
    var userRole = user.role;
  } catch (error) {
    userRole = '';
  }
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.Meno_na_dokladoch_bez_titulu,
      staffPosition: user.Funkcia,
      email: user.email,
      photoURL: user.avatar,
      role: userRole,
    };
  return user;
};

export const getUserFromFmAuth = (user) => {
  try {
    var userRole = user.role;
  } catch (error) {
    userRole = '';
  }
  if (user)
    return {
      id: 1,
      recordId: user.recordId,
      role: userRole,
      fullName: user.fullName,
      shortname: user.Meno_na_dokladoch_bez_titulu,
      acName: user.Prihlasovacie_meno,
      staffPosition: user.Funkcia,
      email: user.email,
      phone: user.Telefon,
      photoURL: user.photoURL,
      company: user.Organizacia_nazov,
      companyId: user.Organizácia_ID,
      financialControl: user.financialControl,
      signature: user.signature,
      paymentsApprove: user.paymentsApprove,
      birthDate: user.birthDate,
      birthCountry: user.birthCountry,
      darkMode: user.darkMode,
      loginEmail: user.loginEmail,
    };
  return user;
};
