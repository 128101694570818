import {
  GET_EVENTS_LIST,
  GET_EVENT_DETAIL,
  UPDATE_EVENT_DETAIL,
  GET_EVENTS_VALUE_LIST,
} from 'shared/constants/ActionTypes';

const initialState = {
  eventsList: [],
  eventsValueList: [],
  totalCount: 0,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.payload,
        totalCount: action.payload.dataInfo.foundCount,
      };
    case GET_EVENTS_VALUE_LIST:
      return {
        ...state,
        eventsValueList: JSON.parse(action.payload),
      };
    case GET_EVENT_DETAIL:
      return {
        ...state,
        eventData: action.payload.data[0].fieldData,
      };
    case UPDATE_EVENT_DETAIL: {
      let id = action.payload.data[0].recordId;
      state.eventData = action.payload.data[0].fieldData;

      const updatedlist = state.eventsList.data.map((e) =>
        id === e.recordId ? action.payload.data[0] : e,
      );

      const newEventList = state.eventsList;

      Reflect.deleteProperty(newEventList, 'data');

      state.eventsList = {...newEventList, data: updatedlist};

      return {
        ...state,
        eventData: action.payload.data[0].fieldData,
      };
    }

    default:
      return state;
  }
};
export default eventsReducer;
