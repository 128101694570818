import React from 'react';
import {Field} from 'formik';
import {DatePicker} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/sk';

const AppDateFiled = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sk'>
      <Field
        component={DatePicker}
        variant={props.variant}
        inputVariant={props.inputVariant}
        format='MM.DD.YYYY'
        //mask='__-__-____'
        {...props}
        renderInput={(params) => (
          <TextField
            size={props.size}
            className={props.className}
            error={props.error}
            helperText={props.helperText}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default AppDateFiled;

AppDateFiled.defaultProps = {
  size: 'normal',
};

AppDateFiled.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  inputVariant: PropTypes.string,
  error: PropTypes.bool,
};
