import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';
import Account from './MyProfile';

export const accountPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
      RoutePermittedRole.Accountant,
    ],
    path: '/my-profile',
    element: <Account />,
  },
];
